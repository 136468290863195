import { Col, Row } from "react-bootstrap"
import icon4 from "@/images/area-with-pins.png"
import React, { useState } from "react"
import styled from "styled-components"

const ContactItem = ({ label, height, children }) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <ContactItemContainer>
      <Row className="header">
        <Col>
          <div className="office--info" onClick={() => setIsActive(!isActive)}>
            <div className={"contactV2--title d-flex align-items-center"}>
              <div className="icon">
                <img src={icon4} alt="contact" />
              </div>
              <span>{label}</span>
              <span className={`handle ${isActive ? "open" : "closed"}`} />
            </div>
          </div>
        </Col>
      </Row>

      <Row
        className={`body ${isActive ? "open" : "closed"}`}
        style={{ maxHeight: height && isActive ? `${height}px` : null }}
      >
        <Col>{children}</Col>
      </Row>
    </ContactItemContainer>
  )
}

export default ContactItem

export const ContactItemContainer = styled.div`
  overflow: hidden;
  & + & {
    margin-top: -1px;
  }
  .header {
    border: 1px solid #dadada;
    cursor: pointer;
  }
  .body {
    transition: all 0.3s ease-in-out;
    max-height: 330px;
    margin-bottom: 16px;
    overflow: hidden;

    u {
      text-decoration-thickness: 1px;
    }


    @media (max-width: 480px) {
      max-height: 700px;
    }

    > div {
      background-color: #fafafa;
      border: 1px solid #dadada;
      border-top: 0;
      padding: 18px;
    }

    &.closed {
      max-height: 0;

      // margin-bottom: 0;
    }
  }

  .handle {
    display: inline-block;
    margin-left: auto;
    width: 12px;
    height: 12px;
    background: transparent;
    text-indent: -9999px;
    border-top: 1px solid #bfbfbf;
    border-left: 1px solid #bfbfbf;
    transition: all 200ms ease-in-out;
    text-decoration: none;
    color: transparent;
    transform: rotate(-135deg);
    transform-origin: 40% 40%;

    &.open {
      transform: rotate(45deg);
    }

    &:before {
      display: block;
      height: 200%;
      width: 200%;
      margin-left: -50%;
      margin-top: -50%;
      content: "";
      transform: rotate(45deg);
    }
  }

  .office--info {
    border-bottom: 0 !important;
  }
  .body {
    p {
      padding: 0.5rem 0 !important;
    }
  }
`
